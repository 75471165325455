<template>
    <div class="image-container">
        <img v-if="imagem != null" :src="imagem" alt="Imagem">
        <span v-else>{{ erro }}</span>
    </div>
</template>

<style scoped>

  body {
    background-color: #333;
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
  }

  .image-container img {
    display: block;
    border-radius: 10px;
    max-height: 100%;
  }

  .image-container span {
    color: white;
  }
  
</style>

<script>
  
  export default {

    data() {
      return {
        imagem: null,
        erro: null
      }
    }, 

    methods: {
      fetch() {
        console.log('Fetching hash ' + this.$route.params.hash)
        this.$http.get('api/v1/midia/' + this.$route.params.hash).then(res => {

          if (res.body.response.success == false) {
            this.erro = res.body.response.msg
            alert(res.body.response.msg)
            return
          }

          this.imagem = res.body.response.data.url

        }).catch(error => {
          console.log('Erro na requisição', error)
        })
      }
    }, 
    mounted() {
      this.fetch()
    }
  }
</script>
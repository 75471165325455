<template>
  <section>
    <b-field
      grouped
      group-multiline
      class="is-mobile has-text-left is-flex is-justify-content-center"
    >
      <div class="column is-2 is-half-tablet">
        <b-field label="Solicitação">
          <b-input
            v-model="inputs.solicitacao"
            placeholder="Número..."
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3 is-half-tablet">
        <b-field class="pr-0 pl-0 is-align-self-flex-end" label="CPF">
          <b-input
            placeholder="999.999.999-99"
            type="search"
            v-mask="'###.###.###-##'"
            v-model="inputs.cpf"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3 is-half-tablet">
        <b-field
          class="pr-0 pl-0 is-align-self-flex-end"
          label="Código da reserva"
        >
          <b-input
            placeholder=""
            type="search"
            v-model="inputs.codigoReserva"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-2 is-half-tablet">
        <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Status">
          <multiselect
            v-model="inputs.status"
            :options="filtros.status"
            :selected="[1, 2]"
            :multiple="true"
            :select-label="''"
            :deselect-label="'x'"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecione"
            label="Descricao"
            track-by="AnaliseStatusID"
            :preselect-first="true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }}
                {{ values.length > 1 ? "Escolhidos" : "Escolhido" }}</span
              ></template
            >
          </multiselect>
        </b-field>
      </div>
    </b-field>
    <b-field
      grouped
      group-multiline
      class="is-mobile has-text-left is-flex is-justify-content-center"
    >
      <div class="column is-4 is-half-tablet">
        <b-field label="Regional">
          <b-select
            :loading="loadingInputs.regional"
            @change.native="mudancaRegional"
            v-model="inputs.regional"
            placeholder="Selecione..."
            expanded
          >
            <option selected="true" value="">Selecione...</option>
            <option
              v-for="regiao in filtros.regionais"
              v-bind:key="regiao.RegiaoID"
              :value="regiao.RegiaoID"
            >
              {{ regiao.Regiao }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-4 is-half-tablet">
        <b-field label="Filial">
          <multiselect
            :loading="loadingInputs.filial"
            v-model="inputs.filiais"
            :options="filtros.filiais"
            :multiple="true"
            :select-label="''"
            :deselect-label="'x'"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecione"
            label="Loja"
            track-by="FilialID"
            :preselect-first="true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }}
                {{ values.length > 1 ? "Escolhidos" : "Escolhido" }}</span
              ></template
            >
          </multiselect>
          <!--<b-select v-model="inputs.filial" placeholder="Selecione..." expanded>
                            <option value="">Selecione...</option>
                            <option v-for="filial in filtros.filiais" v-bind:key="filial.FilialID" :value="filial.FilialID">{{ filial.Loja }}</option>
                        </b-select>-->
        </b-field>
      </div>
    </b-field>

    <b-field
      grouped
      group-multiline
      class="is-mobile has-text-left is-flex is-justify-content-center"
    >
      <div class="column is-3 is-half-tablet">
        <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Tipo">
          <b-select
            v-model="inputs.tipo"
            placeholder="Selecione o tipo"
            expanded
          >
            <option
              v-for="option in filtros.tipo"
              :value="option.AnaliseTipoID"
              :key="option.AnaliseTipoID"
            >
              {{ option.Descricao }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-3 is-half-tablet">
        <b-field class="pr-0 pl-0 is-align-self-flex-end" label="A partir de">
          <b-datepicker
            v-model="inputs.data.inicio"
            placeholder=""
            icon="calendar"
            trap-focus
            editable
            :icon-right="'close-circle'"
            @icon-right-click="clearDate('inicio')"
            icon-right-clickable
          >
          </b-datepicker>
        </b-field>
      </div>

      <div class="column is-3 is-half-tablet">
        <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Até">
          <b-datepicker
            locale="pt-BR"
            v-model="inputs.data.fim"
            placeholder=""
            icon="calendar"
            trap-focus
            editable
            :icon-right="'close-circle'"
            @icon-right-click="clearDate('fim')"
            icon-right-clickable
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="column is-2 is-align-self-flex-end">
        <b-field class="pr-0 pl-0" label="">
          <b-button type="is-primary" @click="loadAsyncData" outlined
            ><i class="fa-solid fa-magnifying-glass fa-fw mr-1"></i>
            Buscar</b-button
          >
          <b-button
            class="ml-2"
            type="is-primary"
            @click="clearForm"
            icon-right="xmark"
          ></b-button>
        </b-field>
      </div>
    </b-field>
    <hr />
    <b-table
      :data="data"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      aria-next-label="Próxima"
      aria-previous-label="Anterior"
      aria-page-label="Page"
      aria-current-label="Página atual"
    >
      <b-table-column field="nrSolicitacao" label="Nr. Solic." v-slot="props">
        <a
          href="#"
          @click.prevent="visualizar(props.row.AnaliseSolicitacaoID)"
          >{{ props.row.AnaliseSolicitacaoID }}</a
        >
      </b-table-column>

      <b-table-column field="data" label="Data e Hora" v-slot="props">
        {{ new dayjs(props.row.DataCriacao).format("DD/MM/YYYY HH:mm:ss") }}
      </b-table-column>

      <b-table-column field="tempo" label="Tempo" v-slot="props">
        {{ props.row.analise_origem ? props.row.Tempo : "N/A" }}
      </b-table-column>

      <b-table-column field="filial" label="Filial" v-slot="props">
        {{ props.row.filial ? props.row.filial.Filial : "N/E" }}
      </b-table-column>

      <b-table-column field="AnaliseTipoID" label="Tipo" v-slot="props">
        {{ props.row.analise_tipo ? props.row.analise_tipo.Descricao : "N/E" }}
      </b-table-column>

      <b-table-column field="CPFCNPJ" label="CPF" v-slot="props">
        {{
          props.row.pessoa
            ? props.row.pessoa.CPFCNPJ.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
              )
            : "N/E"
        }}
      </b-table-column>

      <b-table-column field="Pessoa" label="Nome" v-slot="props">
        {{ props.row.pessoa ? props.row.pessoa.Pessoa : "N/A" }}
      </b-table-column>

      <b-table-column field="solicitante" label="Solicitante" v-slot="props">
        {{ props.row.usuario ? props.row.usuario.Nome : "N/E" }}
      </b-table-column>

      <b-table-column label="Status" v-slot="props">
        {{
          props.row.analise_status ? props.row.analise_status.Descricao : "N/E"
        }}
      </b-table-column>

      <b-table-column label="Conclusão" v-slot="props">
        {{ props.row.ObsConclusao ? props.row.ObsConclusao : "-" }}
      </b-table-column>

      <b-table-column label="Solic. Vinculadas" v-slot="props">
        <b-tag
          class="has-text-weight-bold"
          :type="props.row.AnalisesFilhasCount > 0 ? 'is-primary' : null"
          >{{ props.row.AnalisesFilhasCount }}</b-tag
        >
      </b-table-column>

      <b-table-column
        class="is-flex"
        field="acoes"
        label="Ações"
        v-slot="props"
      >
        <div class="is-flex">
          <b-button
            v-if="props.row.AnaliseStatusID == 1"
            class="is-primary"
            @click="
              confirmarCancelamentoAnalise(props.row.AnaliseSolicitacaoID)
            "
          >
            <b-tooltip label="Cancelar">
              <span class="fa-solid fa-trash"></span>
            </b-tooltip>
          </b-button>

          <b-button v-else disabled>
            <span class="fa-solid fa-trash"></span>
          </b-button>
        </div>
      </b-table-column>
    </b-table>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
    <p v-if="total > 0" class="has-text-centered mt-4">
      Exibindo
      <span class="has-text-weight-bold">{{ perPage }} registro(s)</span> por
      página.
      <span class="has-text-weight-bold">{{ total }} registro(s)</span>
      encontrado(s)
    </p>
  </section>
</template>

<script>
import { mapState } from "vuex";
import services from "@/http";
import * as dayjs from "dayjs";

export default {
  data() {
    return {
      parametros: [],
      isComponentModalActive: false,
      isLoading: false,
      data: [],
      total: 0,
      loading: false,
      loadingInputs: {
        regional: false,
        filial: false,
      },
      page: 1,
      currentPage: 1,
      perPage: 10,

      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: true,

      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",

      hasInput: false,
      paginationOrder: "	is-centered",
      inputPosition: "",
      inputDebounce: "",
      dayjs: dayjs,

      filtros: {
        filiais: [],
        motivo: [],
        origem: [],
        status: [],
        tipo: [],
        regionais: [
          {
            RegiaoID: "",
            Regiao: "",
          },
        ],
      },

      inputs: {
        cpf: "",
        codigoReserva: "",
        solicitacao: "",
        filial: "",
        filialCarregarPadrao: true,
        status: [
          {
            AnaliseStatusID: 1,
            Descricao: "Pendente",
          },
          {
            AnaliseStatusID: 2,
            Descricao: "Em Análise",
          },
        ],
        tipo: null,
        dataInicio: null,
        dataFim: null,
        data: {
          inicio: null,
          fim: null,
        },
        regional: "",
        filiais: [],
      },
    };
  },
  methods: {
    cancelarAnalise($id) {
      this.$http
        .put(`api/v1/analise/${$id}`, { AnaliseStatusID: 5 })
        .then(({ data }) => {
          this.loadAsyncData();
          let payload = data.response.data;
          this.desbloqueioAposCancelamento(payload);
        })
        .catch((error) => {
          console.log('Erro na requisição', error);
        })
        .finally(() => {
          this.$buefy.toast.open({
            message: `Solicitação de análise ${$id} cancelada com sucesso!`,
            type: "is-danger",
          });
        });
    },
    desbloqueioAposCancelamento(data) {
      this.$http
        .post(`api/v1/pessoas/desbloquear`, {
          PessoaID: data.PessoaID,
          UsuarioID: data.UsuarioID,
          Observacao: "Cancelamento de análise pendente",
          BloqueioTipoID: this.parametros["Analise:TipoBloqueioTemporario"],
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log('Erro na requisição', error);
        });
    },
    confirmarCancelamentoAnalise($id) {
      this.$buefy.dialog.confirm({
        title: "Cancelar análise",
        message: "Confirmar o <strong>cancelamento</strong> da solicitação de análise " + $id + "?",
        confirmText: "Sim",
        cancelText: "Não",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.cancelarAnalise($id),
      });
    },
    fetchParametros() {
      this.$http.get("api/v1/parametro?fetch=array").then(function (res) {
        this.parametros = res.body.response;
      }).catch(error => {
        console.log('Erro na requisição', error);
      });
    },
    visualizar(id) {
      this.inputs.filialCarregarPadrao = false;
      this.salvarInputsLocalStorage();
      this.$router.push(`solicitacoes/${id}`).catch(() => {});
    },
    salvarInputsLocalStorage() {
      localStorage.setItem(
        "analise.solicitacoes.filters",
        JSON.stringify(this.inputs)
      );
    },
    clearDate(value) {
      this.inputs.data[value] = null;
    },
    clearForm() {
      this.inputs = {
        cpf: "",
        codigoReserva: "",
        solicitacao: "",
        filial: "",
        status: null,
        tipo: null,
        dataInicio: null,
        dataFim: null,
        filiais: [],
        regional: "",
        data: {
          inicio: null,
          fim: null,
        },
      };
      this.fetchFilial(false);

      localStorage.removeItem("analise.solicitacoes.filters");
    },
    mudancaRegional() {
      this.inputs.filiais = [];
      this.fetchFilial();
    },
    fetchFilial(padrao = true, inicializacao = false) {
      this.loadingInputs.filial = true;

      this.$http
        .get(
          `api/v1/filial?fetch=get&orderBy=Loja,asc&usuario=${this.user.id}&regiao=${this.inputs.regional}`
        )
        .then(({ data }) => {
          this.filtros.filiais = data.response;
          const filial = this.filtros.filiais;
          const filialSelecionada = filial.find(
            (e) => e.FilialID == this.user.filial
          );
          if (
            filialSelecionada != null &&
            this.inputs.regional == "" &&
            padrao == true
          ) {
            this.inputs.filiais = [
              {
                FilialID: filialSelecionada.FilialID,
                Loja: filialSelecionada.Loja,
              },
            ];
          }

          if (inicializacao) {
            this.loadAsyncData();
          }

          // this.loadAsyncData()
        }).catch(error => {
          console.log('Erro na requisição', error);
        })
        .finally(() => {
          this.loadingInputs.filial = false;
        });
    },
    fetchRegional() {
      this.loadingInputs.regional = true;
      this.$http
        .get("api/v1/regionais")
        .then(({ data }) => {
          this.filtros.regionais = data.response.data;
        })
        .catch(error => {
          console.log('Erro na requisição', error);
        })
        .finally(() => {
          this.loadingInputs.regional = false;
        });
    },
    formatarMultiSelect(array, id) {
      if (!array) {
        return [];
      }
      let value = array.map((item) => {
        return item[id];
      });

      return value;
    },

    loadTipoAnalises() {

      services.analiseFraude.analiseMotivo().then(({ data }) => {
        this.filtros.motivo = data;
      }).catch(error => {
          console.log('Erro na requisição', error);
      });

      services.analiseFraude.analiseOrigem().then(({ data }) => {
        this.filtros.origem = data;
      }).catch(error => {
          console.log('Erro na requisição', error);
      });

      services.analiseFraude.analiseStatus().then(({ data }) => {
        this.filtros.status = data;
      }).catch(error => {
          console.log('Erro na requisição', error);
      });

      services.analiseFraude.analiseTipo().then(({ data }) => {
        this.filtros.tipo = data;
      }).catch(error => {
          console.log('Erro na requisição', error);
      });

    },

    loadAsyncData() {
      if (this.inputs.data.inicio != null) {
        this.inputs.dataInicio = this.formatDate(this.inputs.data.inicio);
      }

      if (this.inputs.data.fim != null) {
        this.inputs.dataFim = this.formatDate(this.inputs.data.fim);
      }

      var params = {
        cpf: this.inputs.cpf.replace(/\D/g, ""),
        codigoReserva: this.inputs.codigoReserva,
        solicitacao: this.inputs.solicitacao,
        filial: this.formatarMultiSelect(this.inputs.filiais, "FilialID"),
        nome: this.inputs.nome,
        status: this.formatarMultiSelect(this.inputs.status, "AnaliseStatusID"),
        motivo: this.inputs.motivo,
        origem: this.inputs.origem,
        tipo: this.inputs.tipo,
        dataInicio: this.inputs.dataInicio || "",
        dataFim: this.inputs.dataFim || "",
        perPage: this.perPage,
        regional: this.inputs.regional,
        page: this.page,
      };

      const queryString = this.$route.query;

      if (params.solicitacao == null || params.solicitacao == "") {
        params.apenasAnalisesPai = true;
      }

      if (queryString.solicitacao != undefined) {
        params = { solicitacao: queryString.solicitacao };
      }

      this.loading = true;

      services.analiseFraude
        .listar(params)
        .then(({ data }) => {
          this.data = [];
          let currentTotal = data.total;
          if (data.total / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;
          data.data.forEach((item) => {
            item.release_date = item.release_date
              ? item.release_date.replace(/-/g, "/")
              : null;
            this.data.push(item);
          });
          this.loading = false;
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;
          this.loading = false;
          console.log(error);
          console.log("Falha ao receber dados do endpoint");
        });
    },

    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },

    /*
     * Type style in relation to the value
     */
    type(value) {
      const number = parseFloat(value);
      if (number < 6) {
        return "is-danger";
      } else if (number >= 6 && number < 8) {
        return "is-warning";
      } else if (number >= 8) {
        return "is-success";
      }
    },

    // Formata as datas
    // para o padrão YYYY-mm-dd
    formatDate(dt) {
      if (dt == null || dt == "") {
        return dt;
      }

      let dateString = new Date(dt);
      let newDate = JSON.stringify(dateString);
      return newDate.slice(1, 11);
    },

    loadForm() {
      let filtrosLocalStorage = JSON.parse(
        localStorage.getItem("analise.solicitacoes.filters")
      );

      if (filtrosLocalStorage != null) {
        this.inputs = filtrosLocalStorage;
      }

      if (this.inputs.filial == undefined) {
        this.inputs.filiais = "";
      }

      if (this.inputs.filial == "" && this.inputs.filiaisByPass == undefined) {
        if (this.inputs.filialCarregarPadrao) {
          this.inputs.filial = this.user.filial;
        }
      }

      if (this.inputs.cpf == undefined) {
        this.inputs.cpf = "";
      }

      if (this.inputs.data == undefined) {
        this.inputs.dataInicio = null;
        this.inputs.dataFim = null;
        this.inputs.data = {
          inicio: null,
          fim: null,
        };
      } else {
        if (this.inputs.dataInicio != null) {
          this.inputs.data.inicio = new Date(
            this.inputs.dataInicio + " 00:00:00"
          );
        }

        if (this.inputs.dataFim != null) {
          this.inputs.data.fim = new Date(this.inputs.dataFim + " 00:00:00");
        }
      }

      localStorage.removeItem("analise.solicitacoes.filters");
    },
  },
  mounted() {
    this.fetchParametros();
    this.loadForm();
    this.loadTipoAnalises();
    this.fetchRegional();
    this.fetchFilial(true, true);
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user;
      },
    }),
  },
};
</script>

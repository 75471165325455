<template>    
  <card title="Checklist" subtitle="Ferramentas utilizadas na análise">
    <div v-if="!isLoading">
      <hr/>
      <div class="is-flex">
        <div class="is-flex-grow-1" v-for="(chunk, index) in chunks" :key="index">
          <div v-for="(item, indexItem) in chunk" :key="indexItem">
            <div class="checkbox-item" :class="{'is-disabled': isDisabled}">
              <b-checkbox :disabled="isDisabled" @input="updateSelected" v-model="selected" :native-value="item.AnaliseConclusaoChecklistID">
                <div class="item-content">
                    <span>{{ item.Descricao }}</span>
                </div>
              </b-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-progress type="is-primary" size="is-small"></b-progress>
    </div>
  </card>
</template>

<script>

export default {

  props: {
    analise: {
      type: Object,
      required: false, 
      default: null
    }
  }, 

  data() {
    return {
      isDisabled: false, 
      isLoading: true,
      selected: [],
      chunks: [] // Array de chunks
    };
  },
  
  methods: {
    fetch() {
      this.$http
      .get("api/v1/analise/conclusao/checklists")
      .then((res) => {
        this.createChunks(res.data.response); // Chamar a função createChunks() 
      }).catch(error => {
        console.log('Erro na requisição', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    createChunks(dados) {
      if (dados != undefined && dados != null){
        let chunkSize = dados.length / 2;
        for (let i = 0; i < dados.length; i += chunkSize) {
          const chunk = dados.slice(i, i + chunkSize);
          this.chunks.push(chunk);
        }
      }
    }, 
    updateSelected() {
      this.$root.$emit('checklist-updated', this.selected)
    }
  },

  watch: {
    analise: {
      handler: function (val, oldVal) {
        if (val.analise_conclusao != null) {
          this.isDisabled = true;
        }
      },
      deep: true
    }
  }, 
  
  mounted() {
    this.fetch();

    if (this.analise != null) {

      // Se houver checklists preenchidas na análise, 
      // então vamos percorrer as checklists preenchidas
      // e marcar elas como selecionadas.
      if (this.analise.checklists != undefined && this.analise.checklists.length > 0) {
        this.selected = this.analise.checklists.map((item) => {
          return item.AnaliseConclusaoChecklistID;
        });
      }

      // Se a análise estiver concluída ou cancelada, vamos desabilitar
      // as opções de selecão do checklist.
      if (this.analise.analise_conclusao != null || this.analise.AnaliseStatusID == 5) {
        this.isDisabled = true;
      }
    }
  },
};
</script>

<style>
.is-disabled.b-checkbox.checkbox[disabled] {
  opacity: 1 !important;
}
.b-checkbox.checkbox input[type=checkbox]:disabled:not(:checked) + .check {
  background-color: #eee;
  border-color: #eee;
}
</style>
<template>
    <layout>
        <h1 class="title">Eventos</h1>
        
        <FilterCard @limpar="clearFilter" @filtrar="applyFilter"></FilterCard>

        <card title="Eventos encontrados">
            <div class="table-container">
                <b-table 
                    :loading="isLoading"
                    :data="resources.data" 
                    paginated
                    backend-pagination
                    :total="resources.total"
                    :per-page="resources.per_page"
                    @page-change="onPageChange"
                    :pagination-rounded="true"
                    :striped="true" 
                    :hoverable="true"
                    >

                    <b-table-column label="#" numeric v-slot="props">
                        {{ props.row.EventoID }}
                    </b-table-column>

                    <b-table-column label="Placa" v-slot="props">
                        <strong>{{ props.row.Placa }}</strong>
                    </b-table-column>

                    <b-table-column label="Contrato" numeric v-slot="props">
                        {{ props.row.ContratoID ? props.row.ContratoID : '-' }}
                    </b-table-column>

                    <b-table-column label="Status" v-slot="props">
                        {{ props.row.status.Status }}
                    </b-table-column>

                    <b-table-column label="Dt. movim." v-slot="props">
                        {{ new Date(props.row.DataMovimentacao) | dateFormat('DD/MM/YYYY HH:mm') }}
                    </b-table-column>

                    <b-table-column label="Loja" v-slot="props">

                        <div v-if="props.row.contrato">
                            {{ props.row.contrato.filial_origem.Loja }}
                            <small style="display: none">Contrato</small>
                        </div>

                        <div v-else>
                            {{ props.row.filial ? props.row.filial.Loja : '-' }}
                            <small style="display: none">Payload</small>
                        </div>
                    </b-table-column>

                    <b-table-column label="Regional" v-slot="props">
                        {{ props.row.regional.Regiao }}
                    </b-table-column>

                    <b-table-column label="Operação" v-slot="props">
                        {{ props.row.tipo_operacao.Descricao }}
                    </b-table-column>

                    <b-table-column label="Data de recuperação" v-slot="props">

                        <div v-if="props.row.movimento_recuperacao">
                            {{new Date(props.row.movimento_recuperacao.DataMovimentacao) | dateFormat('DD/MM/YYYY HH:mm') }}
                        </div>
                        <div v-else>-</div>

                        
                    </b-table-column>

                    <b-table-column label="Status da recuperação" v-slot="props">

                        <div v-if="props.row.movimento_recuperacao">
                            {{ props.row.movimento_recuperacao.status.Status }}
                        </div>

                        <div v-else>-</div>
                    </b-table-column>
                </b-table>
            </div>
        </card>
    </layout>
</template>

<script>
    import Layout from '@/components/Layout.vue'
    import FilterCard from '../components/FilterCard.vue'

    export default {

        components: {Layout, FilterCard}, 

        data() {
            return {
                isLoading: true, 
                resources: [],
                filters: {}, 
                page: 1, 
            }
        }, 
        methods: {
            fetch()
            {
                this.isLoading = true
                this.$http.get('api/v1/operacao/evento?page=' + this.page, { params: this.filters }).then(res => {
                    this.resources = res.data;
                    this.isLoading = false;
                }).catch(error => {
                    console.log('Erro na requisição', error)
                })
            }, 
            applyFilter(event)
            {
                console.log(event)
                
                this.filters = event

                if (event.dataEvento != null) {
                    event.dataEvento.forEach((item, index) => {
                        let dataFormatada = new Date(item)
                        this.filters.dataEvento[index] = dataFormatada.toISOString().split('T')[0]
                    })
                }

                if (event.dataRecuperacao != null) {
                    event.dataRecuperacao.forEach((item, index) => {
                        let dataFormatada = new Date(item)
                        this.filters.dataRecuperacao[index] = dataFormatada.toISOString().split('T')[0]
                    })
                }
                
                console.log(this.filters)
                this.fetch()
            }, 

            clearFilter()
            {
                this.filters = {}
                this.fetch()
            },
            
            onPageChange(page)
            {
                this.page = page
                this.fetch()
            }
        }, 
        mounted() {
            this.fetch()
        }
    }

</script>

<template>
	<div>
		<div class="columns">
			<div class="column is-2">
				<b-field label="Nr. Solicitação">
					<b-input v-model="form.SolicitacaoNumero" :loading="isSolicitacaoNumeroLoading" readonly></b-input>
				</b-field>
			</div>
			<div class="column is-3">
				<b-field label="Data">
					<b-input v-model="form.Data" readonly></b-input>
				</b-field>
			</div>
			<div class="column">
				<b-field label="Filial">
					<b-select :disabled="isLoadingFilial" :loading="isLoadingFilial" v-model="form.FilialID" placeholder="Selecione..." expanded>
						<option v-for="filial in filiais" v-bind:key="filial.FilialID" :value="filial.FilialID">{{
							filial.Loja
						}}</option>
					</b-select>
				</b-field>
			</div>
			<div class="column">
				<b-field label="Origem">
					<b-select :disabled="isLoadingOrigem" :loading="isLoadingOrigem" @input="handleOrigemChange($event)" v-model="form.AnaliseOrigemID" placeholder="Selecione..." expanded>
						<option v-for="origem in origens" v-bind:key="origem.AnaliseOrigemID"
							:value="origem.AnaliseOrigemID">{{ origem.Descricao }}</option>
					</b-select>
				</b-field>
			</div>
		</div>

		<div class="columns">
			<div class="column">
				<b-field label="Motivo">
					<b-select :disabled="(form.AnaliseOrigemID == null)" :loading="isLoadingMotivo" v-model="form.AnaliseMotivoID" placeholder="Selecione..." expanded>
						<option v-for="motivo in motivos" v-bind:key="motivo.AnaliseMotivoID" :value="motivo.AnaliseMotivoID">{{ motivo.Descricao }}</option>
					</b-select>
				</b-field>
			</div>
			<div class="column is-2">
				<b-field label="Tipo">
					<b-select :disabled="(form.AnaliseOrigemID == null)" :loading="isLoadingTipo" @input="verificarPreenchimentoCpf($event)" v-model="form.AnaliseTipoID" placeholder="Selecione..." expanded>
						<option v-for="tipo in tipos" v-bind:key="tipo.AnaliseTipoID" :value="tipo.AnaliseTipoID">{{ tipo.Descricao }}</option>
					</b-select>
				</b-field>
			</div>
			
			<div class="column">
				<b-field label="Solicitante">
					<b-input v-model="form.Solicitante" readonly></b-input>
				</b-field>
			</div>
			<div class="column">
				<b-field label="E-mail">
					<b-input v-model="form.Email"></b-input>
				</b-field>
			</div>
			<div class="column">
				<b-field label="Telefone">
					<b-input v-mask="'(##) #####-####'" v-model="form.Telefone"></b-input>
				</b-field>
			</div>
		</div>

		<card title="Reserva" subtitle="Informações da reserva">
			<div class="columns">
				<div class="column is-3">
					<b-field label="Cód. Reserva">
						<b-input v-model="form.CodigoReserva" @blur="fetchReserva" :loading="isReservaLoading"
							:readonly="isReservaReadOnly"></b-input>
					</b-field>
				</div>
				<div class="column">
					<b-field label="Data da Reserva">
						<b-input v-model="reserva.R_Data" readonly></b-input>
					</b-field>
				</div>
				<div class="column">
					<b-field label="Data da Retirada">
						<b-input v-model="reserva.D_Data" readonly></b-input>
					</b-field>
				</div>
				<div class="column is-4">
					<b-field label="Canal">
						<b-input v-model="canal.Canal" readonly></b-input>
					</b-field>
				</div>
			</div>
			<div class="columns">
				<div class="column">
					<b-field label="Detalhes do veículo/Grupo reservado">
						<b-input v-model="grupo.Grupo" readonly></b-input>
					</b-field>
				</div>
			</div>

			<div class="columns">
				<div class="column is-5">
					<div class="columns">
						<div class="column is-5">
							<b-field label="CPF">
								<b-input v-model="pessoa.CNPJ" @blur="fetchPessoa" :readonly="isPessoaReadOnly"
									:loading="isPessoaLoading"></b-input>
							</b-field>
						</div>
						<div class="column">
							<b-field label="Nome">
								<b-input v-model="pessoa.RazaoSocial" readonly></b-input>
							</b-field>
						</div>
					</div>

					<div class="columns">
						<div class="column">
							<b-field label="Observações">
								<b-input maxlength="500" v-model="form.Observacao" type="textarea"
									placeholder="Digite uma observação">
								</b-input>
							</b-field>
						</div>
					</div>
				</div>
				<div class="column">
					<b-field label="Condutor adicional/Responsável financeiro">
						<table class="table is-bordered is-fullwidth">
							<thead>
								<tr>
									<th style="width: 125px;">CPF</th>
									<th>Nome</th>
									<th>Tipo</th>
									<th style="width: 50px;" class="has-text-centered">
										<i class="fa-solid fa-bars fa-fw"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								<!-- Tabela de motoristas e consultas adicionais -->
								<tr v-for="(adicional, indexAdicional) in adicionais" v-bind:key="indexAdicional">
									<td>{{ adicional.Documento }}</td>
									<td>{{ adicional.Titulo }}</td>
									<td>{{ adicional.Tipo }}</td>
									<td class="has-text-centered">
										<a @click.prevent="adicionaisPessoaDelete(indexAdicional)"
											v-if="adicional.Editavel" class="is-text text-danger"><i
												class="fa-solid fa-trash-can"></i></a>
									</td>
								</tr>
								<tr v-if="
									adicionaisCondutoresRestantes > 0 ||
									adicionaisFinanceirosRestantes > 0
								">
									<td colspan="2">
										<b-field>
											<b-input v-model="adicionaisForm.Documento" v-mask="['XXXXXXXXXXXXX', '###.###.###-##', '##.###.###/####-##']">
											</b-input>
										</b-field>
									</td>
									<td colspan="2">
										<b-field>
											<b-select v-model="adicionaisForm.Tipo" placeholder="Tipo do cliente...">
												<option v-if="adicionaisCondutoresRestantes > 0"
													value="Condutor adicional">
													Condutor adicional
												</option>
												<option v-if="adicionaisFinanceirosRestantes > 0"
													value="Responsável financeiro">
													Responsável financeiro
												</option>
											</b-select>
											<p class="control">
												<b-button :loading="isAdicionaisLoading" class="button is-primary"
													@click="adicionaisPessoaFetch">Adicionar</b-button>
											</p>
										</b-field>
									</td>
								</tr>
							</tbody>
						</table>
					</b-field>
				</div>
			</div>
		</card>

		<card :canCollapse="false">
			<b-button type="is-primary is-medium" @click.prevent="save" outlined><i
					class="fa-solid fa-floppy-disk fa-fw mr-2"></i> Salvar informações</b-button>
			<router-link :to="{ name: 'analise.solicitacoes', query: { filtros: true } }">
				<b-button type="is-danger is-medium ml-3" outlined><i class="fa-solid fa-circle-xmark fa-fw mr-2"></i>
					Fechar
				</b-button>
			</router-link>
		</card>

		<!-- Modal com formulário de cadastro -->
		<b-modal v-model="isModalAlertaSolicitacoes" :width="640" scroll="keep">
			<div class="box">
				<p class="title is-6">Atenção! Cliente já possui {{ solicitacoesAnalisePessoa.length }} solicitações de análise<span v-if="solicitacoesAnalisePessoaNaoConcluidas.length > 0">, sendo {{ solicitacoesAnalisePessoaNaoConcluidas.length }} não concluída(s)</span>. O que deseja fazer?</p>
				<b-button @click="gotoSolicitacoesAbertas" class="is-primary mr-2">Visualizar Solicitações</b-button>
				<span v-if="this.parametros['Analise:PermitirAberturaCasoNaoConcluidaMesmoCliente'] == 'S'">
					<b-button @click="isModalAlertaSolicitacoes = false" class="is-secondary mr-2">Prosseguir com Abertura</b-button>
				</span>
				<span v-else>
					<span v-if="this.solicitacoesAnalisePessoaNaoConcluidas.length == 0">
						<b-button @click="isModalAlertaSolicitacoes = false" class="is-secondary mr-2">Prosseguir com Abertura</b-button>
					</span>
				</span>
				<router-link :to="{ name: 'analise.solicitacoes', query: { filtros: true } }">
					<b-button class="is-danger">Cancelar</b-button>
				</router-link>
			</div>
		</b-modal>

		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import flash from '@/mixins/flash.js'
import * as dayjs from 'dayjs';

export default {
	
	mixins: [flash],

	data() {
		return {
			form: {
				SolicitacaoNumero: null,
				Data: null,
				FilialID: null,
				AnaliseTipoID: null,
				AnaliseMotivoID: null,
				AnaliseOrigemID: null,
				Solicitante: null,
				Email: null,
				Telefone: null,
				CodigoReserva: null,
				Observacao: null
			},
			parametros: true, 
			isSolicitacaoNumeroLoading: true,
			isReservaLoading: false,
			isReservaReadOnly: false,
			isPessoaLoading: false,
			isModalAlertaSolicitacoes: false,
			permiteCpf: false, 
			solicitacoesAnalisePessoa: [],
			solicitacoesAnalisePessoaNaoConcluidas: [], 
			isLoading: false,
			isLoadingFilial: true, 
			isLoadingOrigem: true,
			isLoadingMotivo: false,
			isLoadingTipo: false,  

			filiais: [],
			tipos: [],
			motivos: [],
			origens: [],
			reserva: {},
			canal: {},
			grupo: {},
			pessoa: {},
			motoristas: [],

			// Formulário de condutores adicionais
			isAdicionaisLoading: false,
			adicionaisForm: {
				Documento: null,
				Tipo: null,
			},
			adicionais: [],
		};
	},

	methods: {
		fetchParametros() {
			this.$http.get('api/v1/parametro?fetch=array').then((res) => {
				try {
					this.parametros = res.body.response;
				} catch (e) {
					console.error('Unexpected error:', e);
				}
			}).catch((err) => {
				console.log('Erro na requisição', err);
			});
		}, 
		fetchAnaliseSolicitacao() {
			this.$http
				.get(
					"api/v1/analise/solicitacao?fetch=first&orderBy=AnaliseSolicitacaoID,desc"
				)
				.then(({ data }) => {
					if (data.response.AnaliseSolicitacaoID !== undefined) {
						this.form.SolicitacaoNumero =
							data.response.AnaliseSolicitacaoID + 1;
					} else {
						this.form.SolicitacaoNumero = 1;
					}

					this.isSolicitacaoNumeroLoading = false;
				}).catch((err) => {
				console.log('Erro na requisição', err);
			});
		},

		fetchFilial() {
			this.isLoadingFilial = true
			this.$http.get('api/v1/filial?fetch=get&orderBy=Loja,asc&status=1&usuario=' + this.user.id).then(({ data }) => {
				this.filiais = data.response;
				this.isLoadingFilial = false
			}).catch((err) => {
				console.log('Erro na requisição', err)
			})
		},

		fetchTipo(origem) {
			this.isLoadingTipo = true
			this.$http.get('api/v1/dashboard/analiseTipo?filtros=1&ativos=1&selecionaveis=1&origem=' + origem).then(({ data }) => {
				
				if (data.length == 0) {
					this.tipos = []
					this.form.AnaliseTipoID = null
					this.flash('warning', 'Nenhum tipo associado a origem selecionada... Não será possível prosseguir com a abertura da solicitação', 10000)
					return
				}
				
				this.tipos = data;
			}).catch((err) => {
				console.log('Erro na requisição', err)
			}).finally(() => {
				this.isLoadingTipo = false
			});
		},

		fetchMotivo(origem) {
			this.isLoadingMotivo = true

			this.$http.get('api/v1/dashboard/analiseMotivo?filtros=1&ativos=1&selecionaveis=1&origem=' + origem).then(({ data }) => {
				
				if (data.length == 0) {
					this.motivos = []
					this.form.AnaliseMotivoID = null
					this.flash('warning', 'Nenhum motivo associado a origem selecionada... Não será possível prosseguir com a abertura da solicitação', 10000)
				}
				
				this.motivos = data;
			}).catch((err) => {
				console.log('Erro na requisição', err)
			}).finally(() => {
				this.isLoadingMotivo = false
			});
		},

		fetchOrigem() {
			this.isLoadingOrigem = true
			this.$http.get('api/v1/dashboard/analiseOrigem?filtros=1&ativos=1&perfil=' + this.user.perfilId).then(({ data }) => {
				this.origens = data;
				this.isLoadingOrigem = false

				if (data.length == 0) {
					this.flash('warning', 'O seu perfil não possui nenhuma origem de solicitação de análise cadastrada. Não erá possível abrir solicitações de análise', 10000)
				}
			}).catch((err) => {
				console.log('Erro na requisição', err)
			});
		},

		fetchReserva() {

			// Condição para não buscar a reserva se o campo estiver vazio
			// Adicionada para tratar erros dropados no Sentry, onde era esperado um valor
			// e não existia, dropando erro de .length
			if (this.form.CodigoReserva == null || this.form.CodigoReserva == '') {
				return;
			}

			if (this.form.CodigoReserva.length > 5) {
				this.isReservaLoading = true;

				this.$http
					.get(
						"api/v1/reserva?fetch=first&with=canalVenda,grupo,pessoa,pessoaPorCpf,motoristas&codigoReserva=" +
						this.form.CodigoReserva
					)
					.then(({ data }) => {
						if (data.response == null) {
							this.flash(
								"danger",
								"Nenhuma reserva encontrada com o código " +
								this.form.CodigoReserva
							);
							this.form.CodigoReserva = "";
						} else {
							let statusAceitos = [1, 2,3,4];
							let statusAceitosZeroKm = [1, 2, 3,4];

							// Faz a validação referente ao canal de venda
							// Caso for Zero Km, À Confirmar, Confirmada ou Fechada
							// Demais: À confirmar, confirmada

							if(!data.success){
								this.flash(
											"danger",
											data.response.msg
										);
										this.form.CodigoReserva = "";
										this.reserva = this.grupo = this.canal = this.pessoa = this.motoristas = {};
										return false;
							}

							if (data.response.filial_reserva) {
								if (data.response.filial_reserva.L001_UnidadeNegocioID == 5) {
									if (!statusAceitosZeroKm.includes(data.response.StatusRS)) {
										this.flash(
											"danger",
											"Reservas do tipo Zero KM devem possuir o status À confirmar, Confirmada ou Fechada"
										);
										this.form.CodigoReserva = "";
										this.reserva = this.grupo = this.canal = this.pessoa = this.motoristas = {};
										return false;
									}
								} else {
									if (!statusAceitos.includes(data.response.StatusRS)) {
										this.flash(
											"danger",
											"A reserva escolhida deve estar no status À confirmar, Confirmada ou Fechada"
										);
										this.form.CodigoReserva = "";
										this.reserva = this.grupo = this.canal = this.pessoa = this.motoristas = {};
										return false;
									}
								}
							}

							if (data.response.pessoa == null) {

								if (data.response.pessoa_por_cpf == null) {
									this.flash(
										"danger",
										"Nenhum cliente encontrado para a reserva especificada. A reserva não pode ser carregada"
									);
									this.form.CodigoReserva = ''
									this.reserva = this.grupo = this.canal = this.pessoa = this.motoristas = {}
									return false
								} else {
									data.response.pessoa = data.response.pessoa_por_cpf
								}
							}

							this.reserva = data.response;

							this.reserva.R_Data = new dayjs(this.reserva.R_Data).format(
								"DD/MM/YYYY HH:mm"
							);
							this.reserva.D_Data = new dayjs(this.reserva.D_Data).format(
								"DD/MM/YYYY HH:mm"
							);

							this.grupo = data.response.grupo;
							this.canal = data.response.canal_venda;

							this.pessoa = data.response.pessoa;
							this.pessoa.CNPJ = this.pessoa.CNPJ.replace(
								/(\d{3})(\d{3})(\d{3})(\d{2})/,
								"$1.$2.$3-$4"
							);

							let motoristas = data.response.motoristas

							motoristas.forEach(motorista => {
								this.adicionais.push({
									Documento: motorista.CPF.replace(
										/(\d{3})(\d{3})(\d{3})(\d{2})/,
										"$1.$2.$3-$4"
									),
									Titulo: motorista.Nome,
									Tipo: 'Condutor adicional',
									Editavel: false,
									PessoaID: motorista.ClienteID
								});
							});
						}

						this.fetchSolicitacoesDeAnalisePessoa(this.pessoa.CNPJ)

				}).catch((err) => {
					console.log('Erro na requisição', err)
				}).finally(function () {
					this.isReservaLoading = false
				})
			} else {
				this.reserva = {};
				this.canal = {};
				this.grupo = {};
				this.pessoa = {};
				this.motoristas = [];
			}
		},

		fetchPessoa() {

			// Condição para não tratar a pessoa caso o parâmetro seja 
			// vazio ou nulo, evitando erros de lenght no Sentry
			if (this.pessoa.CNPJ == null || this.pessoa.CNPJ == '' || this.pessoa.CNPJ == undefined) {
				return;
			}

			if (this.pessoa.CNPJ.length == 0) {
				this.pessoa = {};
				this.isReservaReadOnly = false;
			}

			if (this.pessoa.CNPJ.length >= 1 && !this.isPessoaReadOnly) {
				this.isPessoaLoading = true;

				this.$http
					.get("api/v1/pessoa?fetch=first", {
						params: { cnpj: this.pessoa.CNPJ },
					})
					.then((res) => {
						this.isReservaReadOnly = true;
						this.CodigoReserva = "";

						if (res.body.response == "") {
							this.flash(
								"danger",
								"Nenhuma pessoa encontrada com o código digitado"
							);
							this.pessoa = {};
							this.isReservaReadOnly = false;
						} else {
							this.pessoa = res.body.response;
							this.pessoa.CNPJ = this.pessoa.CNPJ.replace(
								/(\d{3})(\d{3})(\d{3})(\d{2})/,
								"$1.$2.$3-$4"
							);
						}

						this.isPessoaLoading = false;
						this.fetchSolicitacoesDeAnalisePessoa(this.pessoa.CNPJ);
					}).catch((err) => {
						console.log('Erro na requisição', err)
					})
					.finally(function () {
						this.isPessoaLoading = false;
					});
			} else {
				if (this.form.CodigoReserva.length == 0) {
					this.pessoa = {};
					this.isReservaReadOnly = false;
				}
			}
		},

		fetchSolicitacoesDeAnalisePessoa(pessoa) {
			this.$http
				.post("api/v1/consulta/solicitacaoAnalisePorPessoa?porCpf=1", {
					documento: pessoa,
				})
				.then((res) => {
					if (res.body.response.length) {
						this.solicitacoesAnalisePessoa = res.body.response;

						this.solicitacoesAnalisePessoaNaoConcluidas = this.solicitacoesAnalisePessoa.filter((item) => {
								return item.analise_conclusao == null;
							}
						);

						this.isModalAlertaSolicitacoes = true;
					}
				}).catch((err) => {
				console.log('Erro na requisição', err);
			});
		},

		gotoSolicitacoesAbertas() {
			let routeResolver = this.$router.resolve({
				name: "analise.solicitacoes",
			});

			localStorage.setItem(
				"analise.solicitacoes.filters",
				JSON.stringify({
					filiaisByPass: true,
					filial: "",
					solicitacao: this.solicitacoesAnalisePessoa
						.map((item) => {
							return item.AnaliseSolicitacaoID;
						})
						.join(","),
				})
			);

			window.open(routeResolver.href, "_blank");
		},

		save() {

			this.isLoading = true

			this.$http
				.post("api/v1/dashboard/analiseFraudes", {
					FilialID: this.form.FilialID,
					Email: this.form.Email,
					Telefone: this.form.Telefone,
					CodigoReserva: this.form.CodigoReserva,
					PessoaID: this.pessoa.PessoaID,
					UsuarioID: this.user.id,
					AnaliseTipoID: this.form.AnaliseTipoID,
					AnaliseOrigemID: this.form.AnaliseOrigemID,
					AnaliseMotivoID: this.form.AnaliseMotivoID,
					ObsSolicitacao: this.form.Observacao,
					Adicionais: this.adicionais
				})
				.then(function (res) {
					if (res.body.success) {
						this.flash(
							"success",
							"Solicitação de análise #" +
							res.body.response.AnaliseSolicitacaoID +
							" adicionada com sucesso"
						);
						this.clear();
						this.$router.push({
							name: "analise.solicitacoes",
							query: { filtros: true },
						});
					} else {
						this.flash(
							"danger",
							res.body.response[Object.keys(res.body.response)[0]]
						);
					}

					this.fetchAnaliseSolicitacao();
				}).catch((err) => {
					console.log('Erro na requisição', err)
				}).finally(() => {
					this.isLoading = false
				});
		},

		adicionaisPessoaFetch() {

			if (this.adicionaisForm.Documento == null) {
				this.flash("danger", "Informe um documento");
				return;
			}

			if (this.adicionaisForm.Documento == this.pessoa.CNPJ) {
				this.flash("danger", "O documento informado não pode ser igual ao do condutor principal");
				return;
			}

			if (this.adicionaisForm.Tipo == null) {
				this.flash("danger", "Selecione um tipo (Condutor adicional ou responsável financeiro)");
				return;
			}

			// Verifica se já existe uma pessoa com o mesmo
			// documento
			let documentoExiste = this.adicionais.filter((pessoa) => {
				return pessoa.Documento == this.adicionaisForm.Documento;
			});

			if (documentoExiste.length) {
				this.flash(
					"danger",
					"Já existe uma pessoa com o mesmo documento associado"
				);
				return;
			}

			this.isAdicionaisLoading = true;

			this.$http
				.get("api/v1/pessoa?fetch=first", {
					params: { cnpj: this.adicionaisForm.Documento },
				})
				.then((res) => {

					if (res.body.response == "") {
						this.flash(
							"danger",
							"Nenhuma pessoa encontrada com o código digitado"
						);
						return
					}

					this.adicionais.push({
						Documento: this.adicionaisForm.Documento,
						Titulo: res.body.response.RazaoSocial,
						Tipo: this.adicionaisForm.Tipo,
						Editavel: true,
						PessoaID: res.body.response.PessoaID
					});

					this.adicionaisForm.Documento = null;
					this.adicionaisForm.Tipo = null;

					this.isAdicionaisLoading = false;
				}).catch((err) => {
					console.log('Erro na requisição', err)
				})
				.finally(function () {
					this.isAdicionaisLoading = false;
				});
		},
		adicionaisPessoaDelete(index) {
			this.adicionais.splice(index, 1);
		},

		clear() {
			this.form = {
				NumeroSolicitacao: null,
				Data: new dayjs().format("DD/MM/YYYY HH:mm"),
				FilialID: this.user.filial,
				AnaliseTipoID: null,
				AnaliseMotivoID: null,
				AnaliseOrigemID: null,
				Solicitante: this.user.nome,
				Email: this.user.email,
				Telefone: this.user.celular,
				CodigoReserva: null,
				Observacao: null
			}

			this.reserva = {}
			this.canal = {}
			this.grupo = {}
			this.pessoa = {}
			this.motoristas = []
		}, 

		verificarPreenchimentoCpf(event) {

			let permiteCpf = this.tipos.filter((item) => {
				return item.AnaliseTipoID == this.form.AnaliseTipoID
			})[0].FlgPermiteCpf

			this.form.CodigoReserva = null
			this.reserva = {}
			this.canal = {}
			this.grupo = {}
			this.pessoa = {}
			this.motoristas = []

			this.isReservaReadOnly = false
			this.permiteCpf = permiteCpf == 0 ? false : true
		}, 

		handleOrigemChange(event) {
			this.form.AnaliseOrigemID = event

			this.fetchTipo(this.form.AnaliseOrigemID)
			this.fetchMotivo(this.form.AnaliseOrigemID)

			this.form.CodigoReserva = null
			this.form.AnaliseTipoID = null
			this.form.AnaliseMotivoID = null
			this.reserva = {}
			this.canal = {}
			this.grupo = {}
			this.pessoa = {}
			this.motoristas = []
		},
	},

	mounted() {
		this.clear()
		this.fetchParametros()
		this.fetchAnaliseSolicitacao()
		this.fetchFilial()
		this.fetchOrigem()
	},
	computed: {
		adicionaisCondutoresRestantes() {
			return (
				3 -
				this.adicionais.filter((item) => {
					return item.Tipo == "Condutor adicional";
				}).length
			);
		},
		adicionaisFinanceirosRestantes() {
			return (
				1 -
				this.adicionais.filter((item) => {
					return item.Tipo == "Responsável financeiro";
				}).length
			);
		},

		...mapState({
			user(state) {
				return state.auth.user;
			},
			isPessoaReadOnly: function () {

				if ( ! this.permiteCpf) {
					return true
				}

				if (this.form.CodigoReserva == '') {
					return false
				}

				if (this.form.CodigoReserva == null) {
					return false;
				} else {
					return true;
				}
			},
		}),
	},
};
</script>
<template>
    <div>
        <b-loading v-model="isLoading"></b-loading>
        <div class="box" v-if=" ! isLoading">

            <h4 class="title is-6">Atribuir status de recuperação</h4>

            <div v-if="status.length > 0">
                <div v-for="(item, index) in status" v-bind:key="index" class="mb-4">
                    <h4 class="is-size-6 has-text-weight-bold mb-2">{{ item.unidade_negocio.UnidadeNegocio }} - {{ item.frota_status.Status }}</h4>
    
                    <b-dropdown
                        v-model="statusRecuperacaoSelecionados[item.TipoOperacaoStatusMovID]"
                        :scrollable="true"
                        :max-height="150"
                        multiple
                        >
                        <template #trigger>
                            <b-button expanded>
                                <i class="fa-solid fa-circle-chevron-down fa-fw mr-1"></i> <strong>Selecionar status... - {{ statusRecuperacaoSelecionados[item.TipoOperacaoStatusMovID].length }} selecionado(s)</strong>
                            </b-button>
                        </template>

                        <b-dropdown-item v-for="(recuperacao, indexRecuperacao) in filterStatus(item.StatusID)" :value="recuperacao.StatusID" v-bind:key="indexRecuperacao">
                            <div v-if="recuperacao.StatusID != item.StatusID">{{ recuperacao.Status }}</div>
                        </b-dropdown-item>

                    </b-dropdown>
                </div>

                <hr>
                <b-button @click="save" type="is-primary pulled-right"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>
            </div>

            <div v-else>
                <div class="notification is-warning">
                    <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Não existe nenhum status vinculado nessa operação. É necessário vincular ao menos 1 status em 1 unidade de negócio para associar um status de recuperação.
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

export default {

    props: ['status'], 

    data() {
        return {
            isLoading: true, 
            statusRecuperacao: [], 
            statusRecuperacaoSelecionados: [], 
        }
    }, 

    methods: {
        fetchStatusRecuperacao()
        {
            this.$http.get('api/v1/status/frota').then(res => {
                if (res.body.success) {
                    this.statusRecuperacao = res.body.response
                }
            }).catch(error => {
                console.log('Erro na requisição', error)
            })
        }, 

        filterStatus(except)
        {
            return this.statusRecuperacao.filter((item) => {
                return item.StatusID != except
            })
        }, 

        mountStatusSelecionadosGroup()
        {
            let statusRecuperacaoSelecionados = []
            const pluck = (arr, key) => arr.map(i => i[key]);

            this.status.map(function(item, index) {
                statusRecuperacaoSelecionados[item.TipoOperacaoStatusMovID] = pluck(item.status_recuperacao, 'StatusID')
            })

            this.statusRecuperacaoSelecionados = statusRecuperacaoSelecionados
        }, 

        save() {
            this.$emit('syncStatusRecuperacao', { status: this.statusRecuperacaoSelecionados })
        }
    }, 
        
    mounted() {
        this.isLoading = false
        this.fetchStatusRecuperacao()
        this.mountStatusSelecionadosGroup()
    }
}

</script>
<template>
  <layout>
    <page-title subtitle="Manutenção e gestão dos motivos utilizados nas solicitações de análise">
      Motivos - Solicitações de análise

      <div slot="actions">
        <b-button @click="isFormModalActive = true" type="is-primary is-light"><i class="fa-solid fa-plus fa-fw mr-1"></i> Novo motivo</b-button>
      </div>
    </page-title>
    <hr />

    <data-table ref="dataTable" @edit="edit" @remove="remove"></data-table>

    <!-- Formulário de gestão -->
    <b-modal @close="clear" v-model="isFormModalActive" :width="640" scroll="keep">
      <analise-motivo-form @save="save" :motivo="motivo" ref="analiseMotivoForm"></analise-motivo-form>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    
  </layout>
</template>

<script>

import flash from '@/mixins/flash';

export default {

  mixins: [flash],

  components: {
    DataTable: () => import('@/modules/analise/motivo/components/DataTable.vue'),
    AnaliseMotivoForm: () => import('@/modules/analise/motivo/components/AnaliseMotivoForm.vue')
  },

  data() {
    return {
      isLoading: false, 
      isFormModalActive: false,
      motivo: {}
    }
  },

  methods: {

    edit(params) {
      this.motivo = Object.assign({}, params)
      this.isFormModalActive = true
    },

    clear() {
      this.motivo = {}
    },

    save(params) {
      this.motivo = params
      this.motivo.AnaliseMotivoID === undefined ? this.store(params) : this.update(params)
    }, 

    update(params) {

      this.isLoading = true

      this.$http.post('api/v1/analise/motivo/' + params.AnaliseMotivoID, params).then((response) => {

        if (response.body.success) {
          this.$refs['dataTable'].fetch();
          this.isFormModalActive = false;
          this.flash('success', 'Motivo da análise atualizado com sucesso')
          this.clear()
        } else {
          this.flash('danger', response.body.response[Object.keys(response.body.response)[0]])
        }

        this.isLoading = false

      }).catch((error) => {
        console.log('Erro na requisição', error)
        this.flash('danger', 'Falha ao atualizar o motivo da análise')
      });
    }, 

    store(params) {

      this.isLoading = true

      this.$http.post('api/v1/analise/motivo', params).then((response) => {

        if (response.body.success) {
          this.$refs['dataTable'].fetch();
          this.isFormModalActive = false;
          this.flash('success', 'Motivo da análise salvo com sucesso')
          this.clear()
        } else {
          this.flash('danger', response.body.response[Object.keys(response.body.response)[0]])
        }

        this.isLoading = false

      }).catch((error) => {
        console.log('Erro na requisição', error)
        this.flash('danger', 'Falha ao salvar o motivo da análise')
      });
    }, 

    remove(params) {

      this.isLoading = true

      let confirmacao = confirm('Deseja realmente excluir o motivo da análise?')

      if ( ! confirmacao) {
        this.isLoading = false
        return
      }
      
      this.$http.delete('api/v1/analise/motivo/' + params.AnaliseMotivoID).then((response) => {

        if (response.body.success) {
          this.$refs['dataTable'].fetch();
          this.flash('success', 'Motivo da análise removido com sucesso')
          this.clear()
        } else {
          this.flash('danger', response.body.response.msg)
        }

        this.isLoading = false

        }).catch((error) => {
          console.log('Erro na requisição', error)
          this.flash('danger', 'Falha ao remover o motivo da análise')
        });
    }
  },

};
</script>

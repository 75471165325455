import { render, staticRenderFns } from "./MidiaPage.vue?vue&type=template&id=17bdac5c&scoped=true"
import script from "./MidiaPage.vue?vue&type=script&lang=js"
export * from "./MidiaPage.vue?vue&type=script&lang=js"
import style0 from "./MidiaPage.vue?vue&type=style&index=0&id=17bdac5c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17bdac5c",
  null
  
)

export default component.exports
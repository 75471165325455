<template>
    <layout>
        <page-title subtitle="Página de debug de erros do Sentry">
            Sentry debug
        </page-title>

        <card title="Eventos encontrados">
            <div>
                <p>Para testar os erros lançados no Sentry<br>utilize o botão abaixo, e então<br>verifique no Sentry se o erro<br>foi exibido corretamente.</p>
                <br><br>
                <b-button @click="throwError">Clique para lançar um erro</b-button>
                <br>
            </div>
        </card>
    </layout>
</template>

<script>
    import Layout from '@/components/Layout.vue';

    export default {
        components: {
            Layout
        }, 

        methods: {
            throwError() {

                this.$http.get('api/v1/not-found-endpoint').then(res => {
                    console.log('Resposta da requisição', res)
                })

                new Promise((resolve, reject) => {
                    reject('[DEBUG SENTRY] Erro lançado para testes. Rejeição de promise!')
                })

                throw new Error('[DEBUG SENTRY] Erro lançado para testes na página Debug Sentry do PRF');
            }
        }
    }



</script>

<template>
  <layout>
    <page-title subtitle="Manutenção e gestão das origens utilizados nas solicitações de análise">
      Origens - Solicitações de análise

      <div slot="actions">
        <b-button @click="isFormModalActive = true" type="is-primary is-light"><i class="fa-solid fa-plus fa-fw mr-1"></i> Nova origem</b-button>
      </div>
    </page-title>
    <hr />

    <data-table ref="dataTable" @edit="edit" @remove="remove"></data-table>

    <!-- Formulário de gestão -->
    <b-modal @close="clear" v-model="isFormModalActive" :width="640" scroll="keep">
      <analise-origem-form @save="save" :origem="origem" ref="analiseOrigemForm"></analise-origem-form>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    
  </layout>
</template>

<script>

import flash from '@/mixins/flash';

export default {

  mixins: [flash],

  components: {
    DataTable: () => import('@/modules/analise/origem/components/DataTable.vue'),
    AnaliseOrigemForm: () => import('@/modules/analise/origem/components/AnaliseOrigemForm.vue')
  },

  data() {
    return {
      isLoading: false, 
      isFormModalActive: false,
      origem: {}
    }
  },

  methods: {

    edit(params) {
      this.origem = Object.assign({}, params)
      this.isFormModalActive = true
    },

    clear() {
      this.origem = {}
    },

    save(params) {
      this.origem = params
      this.origem.AnaliseOrigemID === undefined ? this.store(params) : this.update(params)
    }, 

    update(params) {

      this.isLoading = true

      this.$http.post('api/v1/analise/origem/' + params.AnaliseOrigemID, params).then((response) => {

        if (response.body.success) {
          this.$refs['dataTable'].fetch();
          this.isFormModalActive = false;
          this.flash('success', 'Origem de análise atualizada com sucesso')
          this.clear()
        } else {
          this.flash('danger', response.body.response[Object.keys(response.body.response)[0]])
        }

        this.isLoading = false

      }).catch((error) => {
        console.log('Erro na requisição', error)
        this.flash('danger', 'Falha ao atualizar a origem da análise')
      });
    }, 

    store(params) {

      this.isLoading = true

      this.$http.post('api/v1/analise/origem', params).then((response) => {

        if (response.body.success) {
          this.$refs['dataTable'].fetch();
          this.isFormModalActive = false;
          this.flash('success', 'Origem de análise salva com sucesso')
          this.clear()
        } else {
          this.flash('danger', response.body.response[Object.keys(response.body.response)[0]])
        }

        this.isLoading = false

      }).catch((error) => {
        console.log('Erro na requisição', error)
        this.flash('danger', 'Falha ao salvar o origem da análise')
      });
    }, 

    remove(params) {

      this.isLoading = true

      let confirmacao = confirm('Deseja realmente excluir a origem da análise?')

      if ( ! confirmacao) {
        this.isLoading = false
        return
      }
      
      this.$http.delete('api/v1/analise/origem/' + params.AnaliseOrigemID).then((response) => {

        if (response.body.success) {
          this.$refs['dataTable'].fetch();
          this.flash('success', 'Origem da análise removida com sucesso')
          this.clear()
        } else {
          this.flash('danger', response.body.response.msg)
        }

        this.isLoading = false

        }).catch((error) => {
          console.log('Erro na requisição', error)
          this.flash('danger', 'Falha ao remover a origem da análise')
        });
    }
  },

};
</script>

<template>
    <card title="Filtros">
        <div class="columns">
            <div class="column is-2">
                <b-field label="Placa">
                    <b-input v-mask="'XXXXXXX'" v-model="filtros.placa"></b-input>
                </b-field>
            </div>
            <div class="column is-3">
                <b-field label="Unidade de negócio">
                    <b-select v-model="filtros.unidadeNegocio" placeholder="Selecione..." expanded>
                        <option value="">Filtrar todas...</option>
                        <option v-for="(unidade, index) in unidadeNegocio" :value="unidade.L000_UnidadeNegocioID" v-bind:key="index">{{ unidade.UnidadeNegocio }}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-3">
                <b-field label="Status">
                    <b-select v-model="filtros.status" placeholder="Selecione..." expanded>
                        <option value="">Filtrar todos...</option>
                        <option v-for="(status, index) in statusLista" :value="status.StatusID" v-bind:key="index">{{ status.Status }}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Tipo da operação">
                    <b-select v-model="filtros.tipoOperacao" placeholder="Selecione..." expanded>
                        <option value="">Filtrar todas...</option>
                        <option v-for="(tipoOperacao, index) in tipoOperacoes" :value="tipoOperacao.TipoOperacaoID" v-bind:key="index">{{ tipoOperacao.Descricao }}</option>
                    </b-select>
                </b-field>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <b-field label="Data do evento">
                    <b-datepicker v-model="filtros.dataEvento" placeholder="Selecione o período..." range></b-datepicker>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Data da recuperação">
                    <b-datepicker v-model="filtros.dataRecuperacao" placeholder="Selecione o período..." range></b-datepicker>
                </b-field>
            </div>
        </div>
        
        <hr>
        <b-button @click="apply" type="is-primary mr-2"><i class="fa-solid fa-filter mr-1"></i> Filtrar</b-button>
        <b-button @click="clearFilters" type="is-danger" outlined><i class="fa-solid fa-filter-circle-xmark mr-1"></i> Remover filtros</b-button>
    </card>
</template>

<script>
    export default {
        data() {
            return {
                filtros: {
                    placa: '', 
                    unidadeNegocio: '', 
                    status: '', 
                    tipoOperacao: '', 
                    dataEvento: null, 
                    dataRecuperacao: null, 
                }, 

                unidadeNegocio: [], 
                statusLista: [], 
                tipoOperacoes: []
            }
        }, 

        methods: {

            fetchUnidadeNegocio()
            {
                this.$http.get('api/v1/unidade/negocio').then((res) => {
                    this.unidadeNegocio = res.body.response
                }).catch(error => {
                    console.log('Erro na requisição', error)
                })
            }, 

            fetchStatus()
            {
                this.$http.get('api/v1/status/frota').then((res) => {
                    this.statusLista = res.body.response
                }).catch(error => {
                    console.log('Erro na requisição', error)
                })
            }, 

            fetchTipoOperacoes()
            {
                this.$http.get('api/v1/operacao/tipo?all=true').then((res) => {
                    this.tipoOperacoes = res.body.response
                }).catch(error => {
                    console.log('Erro na requisição', error)
                })
            }, 

            clearFilters()
            {
                this.filtros = {
                    placa: '', 
                    unidadeNegocio: '', 
                    status: '', 
                    tipoOperacao: '', 
                    dataEvento: [], 
                    dataRecuperacao: [], 
                }

                this.$emit('limpar')
            }, 

            saveForms()
            {
                // localStorage.setItem('operacao.evento.filters', JSON.stringify(this.filtros))
                // console.log('Form saved!')
            }, 

            loadForms()
            {
                // if (localStorage.getItem('operacao.evento.filters') != null) {
                //     this.filtros = localStorage.getItem('operacao.evento.filters')
                // }
            }, 

            apply()
            {
                this.saveForms()
                this.$emit('filtrar', this.filtros)
            }
        }, 

        created()
        {
            this.fetchUnidadeNegocio()
            this.fetchStatus()
            this.fetchTipoOperacoes()
            this.loadForms()
        }
    }
</script>
<template>
    <section>
        
        <b-field grouped group-multiline class="is-mobile has-text-left is-flex is-justify-content-center ">
            <b-field class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end	" label="CPF">
                <b-input placeholder="999.999.999-99"
                    type="search"
                    v-mask="'###.###.###-##'"
                    v-model="inputs.cpf"
                   >
                </b-input>
            </b-field>
            <b-field class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end	" label="Nome">
                <b-input placeholder=""
                    type="search"
                    v-model="inputs.nome"
                   >
                </b-input>
            </b-field>
        </b-field>
        <b-field grouped group-multiline class="is-mobile has-text-left is-flex is-justify-content-center ">
            <b-field
               
             class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end	" label="A partir de">
                <b-datepicker
                v-model="inputs.dataInicio"
                placeholder=""
                icon="calendar"
                trap-focus
                editable
                :icon-right="'close-circle'"
                @icon-right-click="clearDate('dataInicio')"
                icon-right-clickable
                >
            </b-datepicker>
            </b-field>
            <b-field class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end	" label="Até">
                <b-datepicker
                locale="pt-BR"
                v-model="inputs.dataFim"
                placeholder=""
                icon="calendar"
                trap-focus
                editable
                :icon-right="'close-circle'"
                @icon-right-click="clearDate('dataFim')"
                icon-right-clickable
                >
            </b-datepicker>
            </b-field>
              <b-field class="is-1  pr-0 pl-0  column is-align-self-flex-end	" label="">
                    <b-button type="is-primary" @click="loadAsyncData" outlined>Buscar</b-button>

            </b-field>
        </b-field>

        <br>
        <b-table
            :data="data"
            :loading="loading"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            aria-next-label="Próxima"
            aria-previous-label="Anterior"
            aria-page-label="Page"
            aria-current-label="Página atual"
            

            >

            <b-table-column field="data" label="Data"  v-slot="props">
             {{ new dayjs(props.row.DataCadastro).format('DD/MM/YYYY HH:mm:ss') }}
            </b-table-column>

            <b-table-column  field="filial" label="Filial"  v-slot="props">
                {{ props.row.NomeFilial }}
            </b-table-column>

            <b-table-column  field="cpf" label="CPF"  v-slot="props">
                {{ props.row.CPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }}
            </b-table-column>


            <b-table-column field="nome" label="Nome"  v-slot="props">
                <!-- <router-link :to="{path: `creddefense/${props.row.HistClientesCredDefenseID}`}">{{ props.row.Nome }}</router-link> -->
                <a @click="carregarModalPessoas(props.row.HistClientesCredDefenseID)">{{ props.row.Nome }}</a>


            </b-table-column>
            <b-table-column field="biometria" label="Resultado Biometria"  v-slot="props">
               {{ props.row.ResultadoBiometria }}
            </b-table-column>

        </b-table>

        <section>

        <b-modal
            v-model="isComponentModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Example Modal"
            close-button-aria-label="Close"
            aria-modal>
            <template #default="props">
                <pessoa-modal v-bind:formProps="formProps" @close="props.close"></pessoa-modal>
            </template>
        </b-modal>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

        
    </section>
</template>

<style>
    .is-grouped-multiline{
        align-items: flex-end;
    }

    .b-table .level{
        justify-content: center !important;
    }

    .is-text{
        text-decoration: none !important;
    }

    a.pagination-link.is-current {
        color: #F47920;
        background-color: #FFF0E2;
    }
</style>

<script>

    import services from '@/http';
    import * as dayjs from 'dayjs';
    import PessoaModal from '../components/PessoaModal.vue';


    export default {
        components: {
            PessoaModal
        },
        data() {
            return {
                isComponentModalActive: false,
                formProps: {
                    data: '',
                    cpf: '',
                    nascimento: '',
                    filial: '',
                    nome: '',
                    biometria: '',
                    fotoUrl: '',
                },
                isLoading: false,

                data: [],
                total: 0,
                loading: false,

                page: 1,
                currentPage: 1,
                perPage: 10,

                isPaginated: true,
                isPaginationSimple: false,
                isPaginationRounded: true,
                
                paginationPosition: 'bottom',
                defaultSortDirection: 'asc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
            
                hasInput: false,
                paginationOrder: '	is-centered',
                inputPosition: '',
                inputDebounce: '',
                dayjs: dayjs,

                idPessoa: 15,
                inputs: {
                    cpf: '',
                    nome: '',
                    dataInicio: null,
                    dataFim: null
                }
            }
        },
        methods: {
            clearDate (value) {
                this.inputs[value] = null
            },
            cardModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: PessoaModal,
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true,
                    props: this.formProps
                })
            },
            carregarModalPessoas(idPessoa){
                this.formProps.cpf = '';
                this.formProps.filial = '';
                this.formProps.nome = '';
                this.formProps.biometria = '';
                this.formProps.nascimento = '';
                this.formProps.data = '';
                this.formProps.fotoUrl = '';
                this.isLoading = '';

                this.isLoading = true;
                services.creddefense.pessoa({id: idPessoa})
                .then(res => {
                    const { CPF, FotoUrl,  DataCadastro, DataNasc, NomeFilial, ResultadoBiometria, Nome } = res.body;
                    this.formProps.cpf = CPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                    this.formProps.filial = NomeFilial;
                    this.formProps.nome = Nome;
                    this.formProps.biometria = ResultadoBiometria;
                    this.formProps.nascimento = new dayjs(DataNasc).format('DD/MM/YYYY');
                    this.formProps.data = new dayjs(DataCadastro).format('DD/MM/YYYY HH:mm:ss');
                    this.formProps.fotoUrl = FotoUrl;
                    this.isLoading = false;
                    this.isComponentModalActive = true;
                }).catch(error => {
                    console.log('Erro na requisição', error);
                })
            },

            loadAsyncData() {

                this.inputs.dataInicio = this.formatDate(this.inputs.dataInicio)
                this.inputs.dataFim = this.formatDate(this.inputs.dataFim)

                const params2 = [
                    `cpf=${this.inputs.cpf.replace(/\D/g,'')}`,
                    `nome=${this.inputs.nome}`,
                    //`dataInicio=${this.inputs.dataInicio || ''}`,
                    `dataFim=${this.inputs.dataFim || ''}`,
                    `perPage=${this.perPage}`,
                    `page=${this.page}`
                ].join('&')

                const params = {
                    cpf: this.inputs.cpf.replace(/\D/g,''),
                    nome: this.inputs.nome,
                    dataInicio: this.inputs.dataInicio || '',
                    dataFim: this.inputs.dataFim || '',
                    perPage: this.perPage,
                    page: this.page
                }

                this.loading = true
                    services.creddefense.listar(params)
                    .then(({ data }) => {
                        console.log(data);
                        this.data = []
                        let currentTotal = data.total
                        if (data.total / this.perPage > 1000) {
                            currentTotal = this.perPage * 1000
                        }
                        this.total = currentTotal
                        data.data.forEach((item) => {
                            item.release_date = item.release_date ? item.release_date.replace(/-/g, '/') : null;
                            this.data.push(item)
                        })
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log('Erro na requisição', error)
                        this.data = []
                        this.total = 0
                        this.loading = false
                        throw error
                });

            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

            /*
            * Handle sort event
            */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

            /*
            * Type style in relation to the value
            */
            type(value) {
                const number = parseFloat(value)
                if (number < 6) {
                    return 'is-danger'
                } else if (number >= 6 && number < 8) {
                    return 'is-warning'
                } else if (number >= 8) {
                    return 'is-success'
                }
            }, 

            // Formata as datas 
            // para o padrão YYYY-mm-dd
            formatDate(dt) {

                if (dt == null || dt == '') {
                    return dt
                }
                
                let dateString = new Date(dt)
                let newDate = JSON.stringify(dateString)
                return newDate.slice(1, 11)
            }
        },
        mounted() {
            //this.loadAsyncData()
        }
    }
</script>
<template>
    <section>

        <!-- Grupo de formulários -->
        <b-field grouped group-multiline class="is-mobile has-text-left is-flex is-justify-content-center">
            <div class="column is-2 is-half-tablet">
                <b-field label="Solicitação">
                    <b-input v-model="inputs.solicitacao" placeholder="Número..."></b-input>
                </b-field>
            </div>
            <div class="column is-2 is-half-tablet ">
                <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Documento">
                    <b-input placeholder="Informe um documento..." type="search" v-model="inputs.cpf"></b-input>
                </b-field>
            </div>
            <div class="column is-3 is-half-table">
                <b-field label="Filial">
                    <b-select v-model="inputs.filial" placeholder="Selecione..." expanded>
                        <option value="">Selecione...</option>
                        <option v-for="filial in filtros.filiais" v-bind:key="filial.FilialID" :value="filial.FilialID">{{ filial.Loja }}</option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-2 is-half-tablet ">
                <b-field class="pr-0 pl-0 is-align-self-flex-end	" label="Motivo">
                    <b-select v-model="inputs.motivo" placeholder="Selecione..." expanded>
                        <option v-for="option in filtros.motivo" :value="option.AnaliseMotivoID" :key="option.AnaliseMotivoID">
                            {{ option.Descricao }}
                        </option>
                    </b-select>
                    <!--<multiselect v-model="inputs.motivo" 
                            :options="filtros.motivo" 
                            :multiple="true" 
                            :close-on-select="false" 
                            :clear-on-select="false" 
                            :select-label="''"
                            :deselect-label="'x'"
                            :preserve-search="true" placeholder="Selecione" label="Descricao" 
                            track-by="AnaliseMotivoID" 
                            :preselect-first="true">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ values.length > 1 ? 'Escolhidos' : 'Escolhido' }}</span></template>
                    </multiselect>-->
                </b-field>
            </div>
            <div class="column is-3 is-half-tablet">
                <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Origem">
                    <b-select v-model="inputs.origem" placeholder="Selecione..." expanded>
                        <option v-for="option in filtros.origem" :value="option.AnaliseOrigemID" :key="option.AnaliseOrigemID">
                            {{ option.Descricao }}
                        </option>
                    </b-select>
                </b-field>
            </div>
        </b-field>

        <!-- Grupo de formulários -->
        <b-field grouped group-multiline class="is-mobile has-text-left is-flex is-justify-content-center ">
            <div class="column is-2 is-half-tablet">
                <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Status">
                    <multiselect 
                            v-model="inputs.status" 
                            :options="filtros.status" 
                            :selected=[1,2]
                            :multiple="true" 
                            :select-label="''"
                            :deselect-label="'x'"
                            :close-on-select="false" 
                            :clear-on-select="false" 
                            :preserve-search="true" placeholder="Selecione" label="Descricao" 
                            track-by="AnaliseStatusID" 
                            :preselect-first="true">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ values.length > 1 ? 'Escolhidos' : 'Escolhido' }}</span></template>
                    </multiselect>
                </b-field>
            </div>
            <div class="column is-2 is-half-tablet">
                <b-field class="pr-0 pl-0 is-align-self-flex-end	" label="Tipo">
                    <b-select v-model="inputs.tipo" placeholder="Selecione o tipo" expanded>
                        <option v-for="option in filtros.tipo" :value="option.AnaliseTipoID" :key="option.AnaliseTipoID">
                            {{ option.Descricao }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column is-4 is-half-tablet">
                <b-field class="pr-0 pl-0 is-align-self-flex-end" label="A partir de">
                    <b-datepicker
                        v-model="inputs.data.inicio"
                        placeholder=""
                        icon="calendar"
                        trap-focus
                        editable
                        :icon-right="'close-circle'"
                        @icon-right-click="clearDate('inicio')"
                        icon-right-clickable
                        >
                    </b-datepicker>
                </b-field>
            </div>
            <div class="column is-4 is-half-tablet">
                <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Até">
                    <b-datepicker
                        locale="pt-BR"
                        v-model="inputs.data.fim"
                        placeholder=""
                        icon="calendar"
                        trap-focus
                        editable
                        :icon-right="'close-circle'"
                        @icon-right-click="clearDate('fim')"
                        icon-right-clickable
                        >
                    </b-datepicker>
                </b-field>
            </div>
        </b-field>

        <!-- Grupo de formulários -->
        <b-field grouped group-multiline class="is-mobile has-text-left is-flex is-justify-content-center ">
            <div class="column is-2 is-align-self-flex-end">
                 <b-field class="pr-0 pl-0" label="">
                    <b-button type="is-primary" @click="loadAsyncData" outlined>Buscar</b-button>
                    <b-button class="ml-2" type="is-primary" @click="clearForm"  icon-right="xmark" ></b-button>
                </b-field>
            </div>
        </b-field>

        <!-- Tabela de registros -->
        <b-table 
            class="mt-5" 
            paginated
            backend-pagination
            :data="data"
            :loading="loading"
            :total="total"
            :per-page="perPage"
            :current-page.sync="currentPage"
            @page-change="onPageChange"
            >

            <b-table-column  field="nrSolicitacao" label="Nr. Solic."  v-slot="props">
               <!-- <router-link :to="{path: `analiseFraude/${props.row.AnaliseSolicitacaoID}`}">{{ props.row.AnaliseSolicitacaoID }}</router-link> -->
               <a @click="visualizar(props.row.AnaliseSolicitacaoID)" >{{ props.row.AnaliseSolicitacaoID }}</a>
            </b-table-column>

            <b-table-column field="data" label="Data e Hora" v-slot="props">{{ new dayjs(props.row.DataCriacao).format('DD/MM/YYYY HH:mm:ss') }}</b-table-column>
            <b-table-column field="tempo" label="Tempo" v-slot="props">{{ props.row.analise_origem ? props.row.Tempo: 'N/A' }}</b-table-column>
            <b-table-column field="filial" label="Filial" v-slot="props">{{ props.row.filial ? props.row.filial.Filial : 'N/E' }}</b-table-column>
            <b-table-column field="AnaliseTipoID" label="Tipo" v-slot="props">{{ props.row.analise_tipo ?  props.row.analise_tipo.Descricao : 'N/E' }}</b-table-column>
            <b-table-column field="motivo" label="Motivo" v-slot="props">{{ props.row.analise_motivo ? props.row.analise_motivo.Descricao : 'N/E' }}</b-table-column>
            <b-table-column field="origem" label="Origem" v-slot="props">{{ props.row.analise_origem ? props.row.analise_origem.Descricao : 'N/A' }}</b-table-column>
            <b-table-column field="solicitante" label="Solicitante" v-slot="props">{{ props.row.usuario ?  props.row.usuario.Nome : 'N/E'}}</b-table-column>
            <b-table-column field="analista" label="Analista" v-slot="props">{{ props.row.analista ?  props.row.analista.Nome : '-'}}</b-table-column>

            <b-table-column class="is-flex" field="acoes" label="Ações"  v-slot="props">
                <div class="is-flex">
                    <!--<router-link :to="{path: `analiseFraude/${props.row.AnaliseSolicitacaoID}`}">-->

                    <b-tooltip label="Visualizar" >
                        <b-button @click.native="visualizar(props.row.AnaliseSolicitacaoID)" v-if="props.row.AnaliseStatusID !== 1" type="is-link  is-light"  icon-right="eye" />
                        <b-button @click.native="visualizar(props.row.AnaliseSolicitacaoID)" v-else type="is-primary" icon-right="eye" />
                    </b-tooltip>

                    <div v-if="props.row.AnaliseSolicitacaoIDPai == null">
                        <b-tooltip label="Atribuir" >
                            <b-button @click="atribuir(props.row.AnaliseSolicitacaoID)" v-if="props.row.AnaliseStatusID === 1"  class="ml-2"  type="is-primary"  icon-right="inbox" /> 
                        </b-tooltip>
                    </div>
                    
                    <div v-if="props.row.AnaliseUsuarioID != user.id && props.row.AnaliseStatusID != 5">
                        <div v-if="props.row.AnaliseStatusID !== 3">
                            <div v-if="props.row.AnaliseSolicitacaoIDPai == null">
                                <b-tooltip label="Reatribuir">
                                    <button v-if="props.row.AnaliseStatusID !== 1" @click="atribuir(props.row.AnaliseSolicitacaoID)" class="button ml-2 is-primary"><span class="icon is-small"><i class="fa-solid fa-people-arrows fa-fw"></i></span></button>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        <p v-if="total > 0" class="has-text-centered mt-4">Exibindo <span class="has-text-weight-bold">{{ perPage }} registro(s)</span> por página. <span class="has-text-weight-bold">{{ total }} registro(s)</span> encontrado(s)</p>
    </section>
</template>

<style>
    .is-grouped-multiline{
        align-items: flex-end;
    }

    .b-table .level{
        justify-content: center !important;
    }

    .is-text{
        text-decoration: none !important;
    }

    a.pagination-link.is-current {
        color: #F47920;
        background-color: #FFF0E2;
    }
</style>

<script>

    import services from '@/http';
    import * as dayjs from 'dayjs';
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                isComponentModalActive: false,
                formProps: {
                    data: '',
                    cpf: '',
                    nascimento: '',
                    filial: '',
                    nome: '',
                    biometria: '',
                    fotoUrl: '',
                },
                isLoading: false,
                data: [],
                total: 0,
                loading: false,

                page: 1,
                currentPage: 1,
                perPage: 10,

                isPaginated: true,
                isPaginationSimple: false,
                isPaginationRounded: true,
                
                paginationPosition: 'bottom',
                defaultSortDirection: 'asc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
            
                hasInput: false,
                paginationOrder: '	is-centered',
                inputPosition: '',
                inputDebounce: '',
                dayjs: dayjs,

                filtros: {
                    filiais: [], 
                    motivo: [],
                    origem: [],
                    status: [],
                    tipo: []
                },

                inputs: {
                    cpf: '',
                    motivo: null,
                    solicitacao: '', 
                    filial: '', 
                    origem: null,
                    status: [
                        {
                            AnaliseStatusID: 1,
                            Descricao: "Pendente"
                        },
                        {
                            AnaliseStatusID: 2,
                            Descricao: "Em Análise"
                        }
                    ],
                    tipo: null,
                    dataInicio: null,
                    dataFim: null,
                    data: {
                        inicio: null,
                        fim: null
                    }, 
                    nroSolicitacao: null
                }
            }
        },
        methods: {
            visualizar(id){
                this.salvarInputsLocalStorage();
                this.$router.push(`analiseFraude/${id}`) 
            },
            salvarInputsLocalStorage(){
                localStorage.setItem('painelForm',JSON.stringify(this.inputs));

            },
            atribuir(id){
                this.isLoading = true;
                const params = {
                    AnaliseSolicitacaoID: id
                }

                services.analiseFraude.atribuirAnalise(params)
                    .then(({ data }) => {
                        if(data.success){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: data.msg,
                                position: 'is-bottom',
                                type: 'is-success'
                            })
                            this.salvarInputsLocalStorage();
                            this.$router.push(`analiseFraude/${id}`) 

                            return;
                        }
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: data.msg,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                        
                    }).catch((err) => {
                         this.$buefy.toast.open({
                            duration: 5000,
                            message: "Não foi possível atribuir ao usuário.",
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }).finally(() => {
                        this.isLoading = false;
                    })
              
            },
            clearDate (value) {
                this.inputs.data[value] = null
            },

            clearForm(){
                this.inputs = {
                    cpf: '',
                    motivo: null,
                    solicitacao: null, 
                    filial: null, 
                    origem: null,
                    status: null,
                    tipo: null,
                    dataInicio: null,
                    dataFim: null,
                    data: {
                        inicio: null,
                        fim: null
                    }
                }
                localStorage.removeItem('painelForm');
            },

            formatarTiposAnalise(array,id){
                if(!array){
                    return [];
                }
                let value = array.map( item => {
                    return item[id];
                });

                return value;
            },

            loadTipoAnalises(){
                services.analiseFraude.analiseMotivo()
                    .then(({ data }) => {
                        this.filtros.motivo = data;
                    }).catch(error => {
                        console.log('Erro na requisição', error);
                    });

                services.analiseFraude.analiseOrigem()
                    .then(({ data }) => {
                        this.filtros.origem = data;
                    }).catch(error => {
                        console.log('Erro na requisição', error);
                    });

                services.analiseFraude.analiseStatus()
                    .then(({ data }) => {
                        this.filtros.status = data;
                    }).catch(error => {
                        console.log('Erro na requisição', error);
                    });

                services.analiseFraude.analiseTipo()
                    .then(({ data }) => {
                        this.filtros.tipo = data;
                    }).catch(error => {
                        console.log('Erro na requisição', error);
                    });
            },
  
            loadAsyncData() {
                this.inputs.dataInicio = this.formatDate(this.inputs.data.inicio)
                this.inputs.dataFim = this.formatDate(this.inputs.data.fim)
                
                const params = {
                    cpf: this.inputs.cpf,
                    nome: this.inputs.nome,
                    status: this.formatarTiposAnalise(this.inputs.status,'AnaliseStatusID'),
                    motivo: this.inputs.motivo, 
                    solicitacao: this.inputs.solicitacao, 
                    filial: this.inputs.filial != '' ? [this.inputs.filial] : [], 
                    origem: this.inputs.origem,
                    tipo: this.inputs.tipo,
                    // motivo: this.formatarTiposAnalise(this.inputs.motivo,'AnaliseMotivoID'),
                    //origem: this.formatarTiposAnalise(this.inputs.origem,'AnaliseOrigemID'),
                    //tipo: this.formatarTiposAnalise(this.inputs.tipo,'AnaliseTipoID'),
                    dataInicio: this.inputs.dataInicio || '',
                    dataFim: this.inputs.dataFim || '',
                    perPage: this.perPage,
                    page: this.page
                }

                this.loading = true

                services.analiseFraude.listar(params)
                    .then(({ data }) => {
                        this.data = []
                        let currentTotal = data.total
                        if (data.total / this.perPage > 1000) {
                            currentTotal = this.perPage * 1000
                        }
                        this.total = currentTotal
                        data.data.forEach((item) => {
                            item.release_date = item.release_date ? item.release_date.replace(/-/g, '/') : null;
                            this.data.push(item)
                        })
                        this.loading = false
                    })
                    .catch((error) => {
                        this.data = []
                        this.total = 0
                        this.loading = false
                        console.log(error)
                        console.log('Falha ao receber dados do endpoint')
                });

            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

            /*
            * Handle sort event
            */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

            /*
            * Type style in relation to the value
            */
            type(value) {
                const number = parseFloat(value)
                if (number < 6) {
                    return 'is-danger'
                } else if (number >= 6 && number < 8) {
                    return 'is-warning'
                } else if (number >= 8) {
                    return 'is-success'
                }
            }, 

            fetchFilial() {
                this.$http.get('api/v1/filial?fetch=get&orderBy=Loja,asc&usuario=' + this.user.id).then(({ data }) => {
                    if (data != null && data.response != null) {
                        this.filtros.filiais = data.response;
                    }
                }).catch(error => {
                    console.log('Erro na requisição', error)
                })
            }, 

            // Formata as datas 
            // para o padrão YYYY-mm-dd
            formatDate(dt) {

                if (dt == null || dt == '') {
                    return dt
                }
                
                let dateString = new Date(dt)
                let newDate = JSON.stringify(dateString)
                return newDate.slice(1, 11)
            },
            loadForm(){
                var inputs = JSON.parse(localStorage.getItem('painelForm'))
                const filtros = this.$route.query.filtros;
                
                if(inputs && filtros){
                    this.inputs = inputs;
                    this.inputs.data.inicio = inputs.data.inicio == null ? null : new Date(inputs.data.inicio);
                    this.inputs.data.fim = inputs.data.fim == null ? null : new Date(inputs.data.fim);  
                }
                localStorage.removeItem('painelForm');
            },
        },
        computed: {
        
        ...mapState({
            user(state) {
                return state.auth.user
            }
        })}, 
        mounted() {
            this.loadForm();
            this.loadAsyncData();
            this.loadTipoAnalises();
            this.fetchFilial();
        }, 
        computed: {
            ...mapState({
            user(state) {
                return state.auth.user
            }
        })}
    }
</script>
<template>
    <section>
         <b-field grouped group-multiline class="is-mobile has-text-left is-flex is-justify-content-center ">
            <div class="column is-4 is-half-tablet ">
                <b-field class="pr-0 pl-0 is-align-self-flex-end	" label="CPF">
                    <b-input placeholder="999.999.999-99"
                        type="search"
                        v-model="inputs.cpf"
                    >
                    </b-input>
                </b-field>
            </div>
            <div class="column is-4 is-half-tablet ">
                <b-field class="pr-0 pl-0 is-align-self-flex-end	" label="Contrato">
                    <b-input placeholder="123456789"
                        type="search"
                        v-model="inputs.contrato"
                    >
                    </b-input>
                </b-field>
            </div>
            <div class="column is-4 is-half-tablet ">
                <b-field class="pr-0 pl-0 is-align-self-flex-end	" label="Placa">
                    <b-input placeholder="ABC1D23"
                        type="search"
                        v-model="inputs.placa"
                    >
                    </b-input>
                </b-field>
            </div>
        </b-field>
        <b-field grouped group-multiline class="is-mobile has-text-left is-flex is-justify-content-center ">
            <div class="column is-5 is-half-tablet ">
                <b-field class="pr-0 pl-0 is-align-self-flex-end" label="A partir de">
                    <b-datepicker
                        v-model="inputs.data.inicio"
                        placeholder=""
                        icon="calendar"
                        trap-focus
                        editable
                        :icon-right="'close-circle'"
                        @icon-right-click="clearDate('inicio')"
                        icon-right-clickable
                        >
                    </b-datepicker>
                </b-field>
            </div>

            <div class="column is-5 is-half-tablet ">
                <b-field class="pr-0 pl-0 is-align-self-flex-end	" label="Até">
                    <b-datepicker
                        locale="pt-BR"
                        v-model="inputs.data.fim"
                        placeholder=""
                        icon="calendar"
                        trap-focus
                        editable
                        :icon-right="'close-circle'"
                        @icon-right-click="clearDate('fim')"
                        icon-right-clickable
                        >
                    </b-datepicker>
                </b-field>
            </div>
            <div class="column is-2 is-align-self-flex-end">
                 <b-field class="pr-0 pl-0" label="">
                    <b-button type="is-primary" @click="loadAsyncData" outlined>Buscar</b-button>
                    <b-button class="ml-2" type="is-primary" @click="clearForm"  icon-right="xmark" ></b-button>

            </b-field>
            </div>
        </b-field>
        <br>
    
        <b-table
            :data="data"
            :loading="loading"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            aria-next-label="Próxima"
            aria-previous-label="Anterior"
            aria-page-label="Page"
            aria-current-label="Página atual"
            

            >


            <b-table-column field="dataCriacao" label="Data Criação"  v-slot="props">
             {{ new dayjs(props.row.DataHora).format('DD/MM/YYYY HH:mm:ss') }}
            </b-table-column>

            <b-table-column field="dataModificacao" label="Data Notificação"  v-slot="props">
             {{ props.row.NotificadoEm == null ? '-' : new dayjs(props.row.NotificadoEm).format('DD/MM/YYYY HH:mm:ss') }}
            </b-table-column>
        
            <b-table-column field="anexo" label="Anexado ao Vetor"  v-slot="props">
                <span :class="
                            [
                                'tag',
                                {'is-danger': props.row.ArquivoEnvioEm == null },
                                {'is-success': props.row.ArquivoEnvioEm != null}
                            ]">
                            {{ props.row.ArquivoEnvioEm != null ? 'Sim' : 'Não' }}
                        </span>
            </b-table-column>
        

            <b-table-column field="CPF" label="CPF"  v-slot="props">
             {{ props.row.pessoa.CNPJ.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}}
            </b-table-column>
        

            <b-table-column field="nome" label="Nome"  v-slot="props">
             {{ props.row.pessoa.RazaoSocial}}
            </b-table-column>
        
        
            <b-table-column field="contrato" label="Contrato"  v-slot="props">
             {{ props.row.contrato.ContratoNro}}
            </b-table-column>
        
            <b-table-column field="placa" label="Placa"  v-slot="props">
             {{ props.row.frota.Placa}}
            </b-table-column>
        
            <b-table-column field="email" label="E-Mail"  v-slot="props">
             {{ props.row.NotificadoPara}}
            </b-table-column>
        
        
            <b-table-column field="filial" label="Filial"  v-slot="props">
             {{ props.row.filial.Loja}}
            </b-table-column>
        
            <b-table-column field="status" label="Status"  v-slot="props">
             {{ props.row.status.Status}}
            </b-table-column>
        
            <b-table-column field="usuario" label="Usuário"  v-slot="props">
             {{ props.row.usuario.Nome}}
            </b-table-column>
        

        </b-table>

        <section>

    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

        
    </section>
</template>

<style>
    .is-grouped-multiline{
        align-items: flex-end;
    }

    .b-table .level{
        justify-content: center !important;
    }

    .is-text{
        text-decoration: none !important;
    }

    a.pagination-link.is-current {
        color: #F47920;
        background-color: #FFF0E2;
    }
</style>

<script>

    import services from '@/http';
    import * as dayjs from 'dayjs';


    export default {
        data() {
            return {
                isComponentModalActive: false,
                isLoading: false,
                data: [],
                total: 0,
                loading: false,

                page: 1,
                currentPage: 1,
                perPage: 10,

                isPaginated: true,
                isPaginationSimple: false,
                isPaginationRounded: true,
                
                paginationPosition: 'bottom',
                defaultSortDirection: 'asc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
            
                hasInput: false,
                paginationOrder: '	is-centered',
                inputPosition: '',
                inputDebounce: '',
                dayjs: dayjs,
                inputs: {
                    cpf: '',
                    contrato: null,
                    placa: null,
                    dataInicio: null,
                    dataFim: null,
                    data: {
                        inicio: null,
                        fim: null
                    }
                }
            }
        },
        methods: {
            clearDate (value) {
                this.inputs.data[value] = null
            },

            clearForm(){
                this.inputs = {
                    cpf: '',
                    contrato: null,
                    placa: null,
                    dataInicio: null,
                    dataFim: null,
                    data: {
                        inicio: null,
                        fim: null
                    }
                }
            },
            loadAsyncData() {
                this.inputs.dataInicio = this.formatDate(this.inputs.data.inicio)
                this.inputs.dataFim = this.formatDate(this.inputs.data.fim)
                
                const params = {
                    dataInicio: this.inputs.dataInicio || '',
                    dataFim: this.inputs.dataFim || '',
                    perPage: this.perPage,
                    cpf: this.inputs.cpf.replace(/\D/g,''),
                    contrato: this.inputs.contrato,
                    placa: this.inputs.placa,
                    page: this.page
                }

                this.loading = true

                services.notificacaoMovimentacao.listar(params)
                    .then(({ data }) => {
                        this.data = []
                        let currentTotal = data.data.total
                        if (data.data.total / this.perPage > 1000) {
                            currentTotal = this.perPage * 1000
                        }
                        this.total = currentTotal
                        data.data.data.forEach((item) => {
                            item.release_date = item.release_date ? item.release_date.replace(/-/g, '/') : null;
                            this.data.push(item)
                        })
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log('Erro na requisição', error)
                        this.data = []
                        this.total = 0
                        this.loading = false
                        throw error
                });

            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

            /*
            * Handle sort event
            */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

            /*
            * Type style in relation to the value
            */
            type(value) {
                const number = parseFloat(value)
                if (number < 6) {
                    return 'is-danger'
                } else if (number >= 6 && number < 8) {
                    return 'is-warning'
                } else if (number >= 8) {
                    return 'is-success'
                }
            }, 

            // Formata as datas 
            // para o padrão YYYY-mm-dd
            formatDate(dt) {

                if (dt == null || dt == '') {
                    return dt
                }
                
                let dateString = new Date(dt)
                let newDate = JSON.stringify(dateString)
                return newDate.slice(1, 11)
            },
        },
        mounted() {
            this.loadAsyncData();
        }
    }
</script>
import { routes as auth } from "../modules/auth";
import { routes as dashboard } from "../modules/dashboard";
import { routes as operacoesEvento } from "../modules/operacao/evento";
import { routes as operacoesEventoStatus } from "../modules/operacao/statusEvento";
import { routes as operacoesPrestador } from "../modules/operacao/prestador";
import { routes as operacoesTipo } from "../modules/operacao/tipo";
import { routes as analiseSolicitacao } from "../modules/analise/solicitacao";
import { routes as parametros } from "../modules/parametros";
import { routes as parametrosFilial } from "../modules/parametros_filial";

import { routes as acompanhamentoValidacaoBiometrica } from "../modules/acompanhamento/validacao/biometrica";
import { routes as analiseMotivo } from "../modules/analise/motivo";
import { routes as analiseTipo } from "../modules/analise/tipo";
import { routes as analiseOrigem } from "../modules/analise/origem";
import { routes as midia } from "../modules/midia";

import SentryDebug from "../pages/SentryDebug";

export default [
  ...auth,
  ...dashboard,
  {
    path: "/",
    name: "home",
    redirect: { name: "dashboard_home" },
    meta: { permission: "dashboard.home" },
  },
  ...operacoesEvento,
  ...operacoesTipo,
  ...analiseSolicitacao,
  ...parametros,
  ...acompanhamentoValidacaoBiometrica,
  ...operacoesEventoStatus,
  ...operacoesPrestador, 
  ...analiseMotivo, 
  ...analiseTipo,
  ...analiseOrigem, 
  ...midia,
  ...parametrosFilial, 
  {
    path: "/sentry-debug", 
    name: "sentry_debug",
    component: SentryDebug
  }
];

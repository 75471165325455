<template>

    <card title="Reservas" subtitle="Reservas associados nessa solicitação de análise">
        <div v-if=" ! isLoading">
            <div v-if="data.length > 0">
                <b-table 
                    :data="data" 
                    :striped="true" 
                    :hoverable="true" >

                    <b-table-column label="Reserva" v-slot="props">
                        <a :href="props.row.Url" target="_blank">{{ props.row.ReservaID }}</a>
                    </b-table-column>
                    
                    <b-table-column label="Código da reserva" v-slot="props">
                        <a :href="props.row.Url" target="_blank">{{ props.row.CodigoReserva }}</a>
                    </b-table-column>

                    <b-table-column label="Data da reserva" v-slot="props">
                        {{ new Date(props.row.DataReserva) | dateFormat('DD/MM/YYYY HH:mm') }}
                    </b-table-column>

                    <b-table-column label="Data da retirada" v-slot="props">
                        {{ new Date(props.row.DataRetirada) | dateFormat('DD/MM/YYYY HH:mm') }}
                    </b-table-column>

                    <b-table-column label="Canal" v-slot="props">
                        {{ props.row.canal.Canal }}
                    </b-table-column>

                    <b-table-column label="Detalhes do veículo" v-slot="props">
                        {{ props.row.grupo?.Modelos }}
                    </b-table-column>
                </b-table>
            </div>
            <div v-else class="notification is-warning is-light">
                <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhuma reserva associada a solicitação
            </div>
        </div>

        <div v-else>
            <b-progress type="is-primary" size="is-small"></b-progress>
        </div>
    </card>
</template>

<script>

import services from '@/http';

export default {

    data() {
        return {
            isLoading: true, 
            data: []
        }
    }, 

    methods: {
        fetch() {
            this.$http.get('api/v1/analise/' + this.$route.params.id + '/reservas').then(function(res) {
                this.data = res.data
            }).catch(error => {
                console.log('Erro na requisição', error)
            }).finally(function() {
                this.isLoading = false
            });
        }, 
    }, 

    mounted() {
        this.$root.$on('resource-loaded', () => {
            this.fetch()
        })
    }

}

</script>